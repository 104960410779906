import { useEffect, useState } from "react";

import axios, { AxiosRequestConfig } from "axios";
import useSWR, { Fetcher } from 'swr'

const fetcher: Fetcher = async (url, data, config) => await axios.put(url, data, config as AxiosRequestConfig).then(response => ({
  ...response.data,
  status: 'success',
}))

function getBinaryFromFile(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener("load", () => resolve(reader.result));
      reader.addEventListener("error", err => reject(err));

      // reader.readAsDataURL(file);
      // reader.readAsBinaryString(file);
      reader.readAsArrayBuffer(file)
  });
}

interface IFileState extends Partial<File> {
  data: string | ArrayBuffer | null
}

const useGCS = (path?: string) => {
  const [state, setState] = useState<string>();
  const [file, setFile] = useState<IFileState>();
  const { data, mutate } = useSWR(
    path && file ? [state, file.data, { headers: { "Content-Type": file.type } }] : null, 
    fetcher
  );

  const add = async (newFile: File) => {
    const binary = await getBinaryFromFile(newFile);

    setFile({
      type: newFile.type,
      data: binary
    });
  }

  const upload = () => {
    mutate();
  }

  useEffect(() => {
    setState(path);
  }, [path])

  return {
    data,
    add,
    upload
  }
}

export { useGCS }