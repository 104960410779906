import { Balance, ID, OptionData, PollData, TierKeys } from "types/api";

import axios from "../utils/axios";

const DEFAULT_ROUTE = "/polls";

export interface PollResponse extends PollData {}

export const getPolls = async () => {
  try {
    return await axios.get(`${DEFAULT_ROUTE}`);
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export interface PollQuery {
  field: keyof PollResponse;
  order?: keyof PollResponse;
  where_opStr: string;
  where_value: string | number | Date;
}

export const getFilteredPolls = async ({
  field,
  order,
  ...data
}: PollQuery) => {
  try {
    const url = `${DEFAULT_ROUTE}/find/${field}${
      order ? `/order/${order}` : ``
    }`;

    return await axios.get(url, { params: data });
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const getPoll = async (id: ID) => {
  try {
    return await axios.get(`${DEFAULT_ROUTE}/${id}`);
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export interface PollReq
  extends Omit<
    PollData,
    "id" | "creator" | "options" | "winner" | "voteCount" | "teaser" | "roles" | "expired"
  > {
  options: string;
  file: File['type'];
}

export const createPoll = async (data: PollReq) => {
  try {
    return await axios.post(`${DEFAULT_ROUTE}/new`, data);
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export interface VoteReq {
  pollId: PollData["id"];
  optionId: OptionData["id"];
  tier: TierKeys;
}

export const vote = async ({ pollId, optionId, tier }: VoteReq) => {
  try {
    return await axios.post(
      `${DEFAULT_ROUTE}/${pollId}/options/${optionId}/vote/${tier}`,
      { key: tier }
    );
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export interface DareReq {
  pollId: PollData["id"];
  balance: Balance;
  title: String;
}

export const dare = async ({ title, balance, pollId }: DareReq) => {
  try {
    return await axios.post(`${DEFAULT_ROUTE}/${pollId}/options/dare/new`, {
      title,
      balance,
    });
  } catch (error) {
    console.error(error);
  } finally {
  }
};
